import { BrowserPlatformLocation, PlatformLocation } from '@angular/common';
import { Injectable, afterNextRender } from '@angular/core';

// 类型别名，可以添加该类型的任意多个对象
type Org = Record<string, any>;

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(private platformLocation: PlatformLocation) {
    afterNextRender(() => {
      this.getLocalStorage()
    })
  }

  getLocalStorage(): Storage | null {
    if (this.platformLocation instanceof BrowserPlatformLocation) {
      return localStorage;
    }
    return null;
  }

  getToken() {
    const localStorage = this.getLocalStorage();
    if (localStorage) {
      const token = localStorage.getItem('token');
      return token;
    }
    return null;
  }

  setToken(token: string) {
    const localStorage = this.getLocalStorage();
    if (localStorage) {
      localStorage.setItem('token', token);
    }
  }

  removeToken() {
    const localStorage = this.getLocalStorage();
    if (localStorage) {
      localStorage.removeItem('token');
    }
  }

  // 存储本地资料
  saveJsonData(key: string, value: any) {
    const localStorage = this.getLocalStorage();
    if (localStorage) {
      // 1 读取整个字符串
      let myStr = localStorage.getItem('data');

      // 2. 转换为对象
      let myOrg: Org = {};

      if (!!myStr) {
        myOrg = JSON.parse(myStr);
      }
      myOrg[key] = value;

      // 3. 存储整个对象
      const jsonStr = JSON.stringify(myOrg);
      localStorage.setItem('data', jsonStr);
    }
  }

  // 返回具体的某个属性值
  readJsonData(key: string): any {
    const localStorage = this.getLocalStorage();
    if (localStorage) {
      const data = localStorage.getItem('data');
      if (!!data) {
        let jsonObj = JSON.parse(data);
        if (key in jsonObj) {
          return jsonObj[key];
        }
      }
      return null;
    }
  }

  clearAllData() {
    const localStorage = this.getLocalStorage();
    if (localStorage) {
      localStorage.clear();
    }
  }

  clearToken() {
    const localStorage = this.getLocalStorage();
    if (localStorage) {
      localStorage.removeItem('token');
    }
  }
}
